/* @jsx jsx */
import { useState, useRef, useMemo, useEffect } from "react";
import { jsx } from "theme-ui";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";

import {
  useGetFeedbackQuery,
  Feedback,
  Categories,
  Status,
  GetFeedbackQuery,
  useSendWidgetInstructionsMutation,
} from "../graphql/queries/_generated";
import Checkout from "../components/Checkout";
import Onboarding from "./OnboardingModal";
import { ReactComponent as CircleQuarter } from "../../static/circle-quarter.svg";
import { Spinner } from "@theme-ui/components";
import tw from "twin.macro";
import React from "react";
import { isHiddenFeedback, createOption } from "../../utils";
import { FilterCategory, QueryParams } from "../../types/app-types";
import FeedbackList from "../components/FeedbackList";
import useFakeLoad from "../Hooks/useFakeLoad";
import Sidebar, {
  SidebarButton,
  SidebarGroup,
  SidebarTitle,
} from "../components/Sidebar";
import { colorMap } from "../../utils";
import { Button } from "./Button";
import { splitbee } from "../splitbee";

type Props = { id: string } & QueryParams;

const fakeFeedback: GetFeedbackQuery["feedback"][0] = {
  id: -1,
  text:
    "This is what your customer feedback will look like once you integrate the widget into your website! Why don't you try clicking 'Reply with Mail' below and sending me an email?",
  status: Status.New,
  category: Categories.Other,
  createdAt: new Date(),
  location: "https://example.org/page",
  userId: "info@feedback.fish",
  screenshotUrl: null,
  device: {
    client: { name: "Chrome", version: "84.0", __typename: "Client" },
    os: { name: "Mac", version: "10.15", __typename: "OS" },
    __typename: "Device",
  },
  __typename: "Feedback",
};

const Project = ({
  id,
  success,
  data,
}: Props & {
  data: GetFeedbackQuery;
}) => {
  const [filterCategory, setFilterCategory] = useState<FilterCategory>("all");
  const [activeFeedback, setActiveFeedback] = useState<Feedback["id"]>();
  const [showOnboardingModal, setShowOnboardingModal] = useState<
    boolean | null
  >(null);
  const [visibleAmount, setVisibleAmount] = useState<number>(25);

  const feedback: GetFeedbackQuery["feedback"] =
    data.feedback.length > 0 ? data.feedback : [fakeFeedback];

  const showOnboarding = () => {
    splitbee()?.track("Show Onboarding");
    setShowOnboardingModal(true);
  };

  // Only if the value is the initial value trigger opening the modal
  // but not when the user has explicitly closed it and the value is "false"
  useEffect(() => {
    if (showOnboardingModal === null && data?.feedback.length === 0) {
      showOnboarding();
    }
  }, [data]);

  // Reset show onboarding modal when switching projects
  useEffect(() => {
    if (showOnboardingModal !== null) setShowOnboardingModal(null);
  }, [id]);

  // Track successful payment redirects
  useEffect(() => {
    if (success) {
      splitbee()?.track("Successful Payment");
    }
  }, [success]);

  const filteredFeedback = useMemo(
    () =>
      feedback.filter((f) => {
        if (filterCategory === "archive") return f.status === Status.Archived;

        return (
          f.status !== Status.Archived &&
          (f.category === filterCategory || filterCategory === "all")
        );
      }) || [],
    [feedback, filterCategory]
  );

  const hasMore = visibleAmount < filteredFeedback.length;

  const scrollToRef = useRef<HTMLDivElement>(null);

  const [fakeLoading, setFakeLoading] = useFakeLoad(10);
  const infiniteRef = useInfiniteScroll({
    hasNextPage: hasMore,
    loading: fakeLoading,
    threshold: 500,

    onLoadMore: () => {
      setFakeLoading(true);
      setVisibleAmount(visibleAmount + 10);
    },
  });

  // PMF Survey
  React.useEffect(() => {
    if (!data || data.feedback.length < 10) return;
    if (!localStorage.getItem("survey")) {
      splitbee()?.track("Show PMF Survey");
      localStorage.setItem("survey", "seen");
      (function () {
        var js,
          q,
          d = document,
          gi = d.getElementById,
          ce = d.createElement,
          gt = d.getElementsByTagName,
          id = "typef_orm_share",
          b = "https://embed.typeform.com/";
        if (!gi.call(d, id)) {
          js = ce.call(d, "script");
          js.id = id;
          (js as any).src = b + "embed.js";
          q = gt.call(d, "script")[0];
          (q as any).parentNode.insertBefore(js, q);
        }
      })();
    }
  }, [data]);

  const selectFilterCategoryInCallback = (category: FilterCategory) => () => {
    setVisibleAmount(20);
    setFilterCategory(category);

    const targetScroll = (scrollToRef.current?.offsetTop || 0) - 50;
    // Scroll to begin of list
    if (targetScroll < window.scrollY) {
      window.scrollTo({
        top: targetScroll,
      });
    }
  };

  const closeOnboarding = () => {
    splitbee()?.track("Close Onboarding");
    setShowOnboardingModal(false);
  };

  return (
    <div
      sx={{
        flex: 1,
        mb: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <a
        className="typeform-share button"
        href="https://form.typeform.com/to/CdKrneju"
        data-mode="popup"
        data-auto-open="true"
        data-hide-headers="true"
        target="_blank"
        sx={{ visibility: `hidden` }}
      ></a>
      {success && (
        <div
          sx={{
            backgroundColor: "green.2",
            px: 4,
            py: 4,
            mb: 4,
            borderBottomRightRadius: "96px",
            position: "relative",
            borderLeft: "6px solid ",
            borderColor: "green.6",
          }}
        >
          <div
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CircleQuarter
              sx={{ fill: "green.6", width: "60px", height: "60px" }}
            />
          </div>
          <h1
            sx={{
              fontFamily: "PT Serif",
              lineHeight: 1.2,
              fontSize: ["2rem", 4],
              margin: 0,
              color: "green.7",
            }}
          >
            Payment successful! 🎉
          </h1>
          <p
            sx={{
              margin: 0,
              lineHeight: 1.5,
              py: 2,
              fontFamily: "Roboto",
              fontSize: 2,
              color: "green.8",
              maxWidth: "600px",
            }}
          >
            Thank you for supporting Feedback Fish, we are happy to have you
            onboard!
          </p>
        </div>
      )}

      <React.Fragment>
        {data.feedback.length === 0 && (
          <Onboarding
            isOpen={!!showOnboardingModal}
            onDismiss={closeOnboarding}
            projectId={id}
          />
        )}

        {data?.feedback.length === 0 && (
          <div
            sx={{
              backgroundColor: "blue.2",
              px: 4,
              py: 4,
              mb: 4,
              borderBottomRightRadius: "96px",
              position: "relative",
              borderLeft: "6px solid ",
              borderColor: "blue.6",
            }}
          >
            <div
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CircleQuarter
                sx={{ fill: "blue.6", width: "60px", height: "60px" }}
              />
            </div>
            <h1
              sx={{
                fontFamily: "PT Serif",
                lineHeight: 1.2,
                fontSize: ["2rem", 4],
                margin: 0,
                color: "blue.7",
              }}
            >
              Welcome to your Feedback Fish dashboard! 👋
            </h1>
            <p
              sx={{
                margin: 0,
                mb: 3,
                lineHeight: 1.5,
                py: 2,
                fontFamily: "Roboto",
                fontSize: 2,
                color: "blue.8",
                maxWidth: "650px",
              }}
            >
              Integrate the widget into your website and start collecting
              customer feedback.
            </p>
            <Button
              primary
              href="#"
              onClick={showOnboarding}
              sx={{
                py: 1,
              }}
            >
              Integrate widget
            </Button>
          </div>
        )}

        {/* Null state: show success message until their first users submit feedback */}
        {data?.feedback.length === 1 && (
          <div
            sx={{
              backgroundColor: "green.2",
              px: 4,
              py: 4,
              mb: 4,
              borderBottomRightRadius: "96px",
              position: "relative",
              borderLeft: "6px solid ",
              borderColor: "green.6",
            }}
          >
            <div
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CircleQuarter
                sx={{ fill: "green.6", width: "60px", height: "60px" }}
              />
            </div>
            <h1
              sx={{
                fontFamily: "PT Serif",
                lineHeight: 1.2,
                fontSize: ["2rem", 4],
                margin: 0,
                color: "green.7",
              }}
            >
              Yay, you're all set up! 🎉
            </h1>
            <p
              sx={{
                margin: 0,
                lineHeight: 1.5,
                py: 2,
                fontFamily: "Roboto",
                fontSize: 2,
                color: "green.8",
                maxWidth: "600px",
              }}
            >
              Check your inbox, we sent you an email with your feedback
              submission!
              <br />
              Now just sit back, relax and watch the user feedback trickle in.
            </p>
          </div>
        )}

        {feedback.some((f) => isHiddenFeedback(f)) && <Upsell id={id} />}

        {/* Data state: show their feedback! */}
        <React.Fragment>
          <h1
            sx={{
              fontFamily: "PT Serif",
              lineHeight: 1.2,
              textAlign: "center",
              fontSize: 4,
              mt: 4,
              mb: 5,
            }}
          >
            Feedback
          </h1>
          <div
            ref={scrollToRef}
            sx={{
              flex: 1,
              display: "grid",
              gap: [2, 2, 3, 5, 5],
              gridTemplateColumns: ["1fr", "1fr", "2fr 5fr", "2fr 5fr"],
            }}
          >
            <div>
              <Sidebar>
                {[
                  [
                    {
                      category: "all",
                      count: feedback.filter(
                        (f) => f.status !== Status.Archived
                      ).length,
                      style: "solid",
                    },
                    createOption(Categories.Issue, feedback),
                    createOption(Categories.Idea, feedback),
                    createOption(Categories.Other, feedback),
                  ],
                  [
                    {
                      category: "archive",
                      style: "outline",
                      count: feedback.filter(
                        (f) => f.status === Status.Archived
                      ).length,
                    },
                  ],
                ].map((group, index) => (
                  <SidebarGroup>
                    {index === 0 && <SidebarTitle>Filter</SidebarTitle>}
                    {group.map((option) => {
                      return (
                        <SidebarButton
                          text={option.category}
                          active={option.category === filterCategory}
                          colors={colorMap[option.category as FilterCategory]}
                          style={option.style as "outline" | "solid"}
                          count={option.count}
                          onClick={selectFilterCategoryInCallback(
                            option.category as FilterCategory
                          )}
                        ></SidebarButton>
                      );
                    })}
                  </SidebarGroup>
                ))}
              </Sidebar>
            </div>
            <div ref={infiniteRef as any}>
              <FeedbackList
                feedback={
                  filteredFeedback.slice(
                    0,
                    visibleAmount
                  ) as any /* Mismatch due because not all device info is fetched */
                }
                activeFeedbackId={activeFeedback}
                onSelect={setActiveFeedback}
              />
              {hasMore && (
                <div sx={tw`flex justify-center m-2`}>
                  <div
                    sx={tw`px-4 py-1 text-sm text-gray-700 bg-gray-100 rounded-full`}
                  >
                    Loading...
                  </div>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>

        <p sx={tw`mt-4 text-sm text-center text-gray-500`}>Project ID: {id}</p>
      </React.Fragment>
    </div>
  );
};

export default (props: Props) => {
  const [res] = useGetFeedbackQuery({
    variables: { projectId: props.id },
    pollInterval: 5000,
    requestPolicy: "cache-and-network",
  });

  if (res.fetching) {
    return (
      <div
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size={24} color="brandBlue.2" />
      </div>
    );
  }

  if (res.data) return <Project {...props} data={res.data} />;

  return null;
};

function Upsell({ id }: { id: string }) {
  useEffect(() => {
    splitbee()?.track("Show Hidden Feedback Upsell");
  }, []);

  return (
    <div
      sx={{
        backgroundColor: "brandOrange.0",
        px: 4,
        py: 4,
        mb: 4,
        position: "relative",
        borderBottomRightRadius: "96px",
        borderLeft: "6px solid ",
        borderColor: "brandOrange.3",
      }}
    >
      <div
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CircleQuarter
          sx={{
            fill: "brandOrange.3",
            width: "60px",
            height: "60px",
          }}
        />
        <CircleQuarter
          sx={{
            fill: "brandOrange.2",
            width: "60px",
            height: "60px",
          }}
        />
      </div>
      <h1
        sx={{
          fontFamily: "PT Serif",
          lineHeight: 1.2,
          fontSize: 4,
          margin: 0,
          color: "brandOrange.3",
        }}
      >
        You've reached the Limit!
      </h1>
      <p
        sx={{
          margin: 0,
          lineHeight: 1.5,
          py: 2,
          fontFamily: "Roboto",
          fontSize: 2,
          color: "brandOrange.4",
          maxWidth: "600px",
        }}
      >
        You already have more than 25 feedback submissions. <br />
        Please upgrade to our paid plan to unlock all submitted feedback.
      </p>
      <Checkout projectId={id} />
    </div>
  );
}

/* @jsx jsx */
import { jsx } from "theme-ui";
import { Feedback } from "../graphql/queries/_generated";
import { AnimateSharedLayout, motion } from "framer-motion";
import FeedbackCard from "./FeedbackCard";

interface FeedbackListProps {
  feedback: Feedback[];
  activeFeedbackId?: Feedback["id"];
  onSelect: (feedbackId: Feedback["id"]) => void;
}

const FeedbackList: React.FC<FeedbackListProps> = ({
  feedback,
  activeFeedbackId,
  onSelect,
}) => {
  return (
    <AnimateSharedLayout>
      <motion.div sx={{ display: "grid", gap: 3, flex: 1 }}>
        {feedback.map((f) => (
          <FeedbackCard
            key={f.id}
            feedback={f}
            active={f.id === activeFeedbackId}
            onSelect={() => onSelect(f.id)}
          />
        ))}
      </motion.div>
    </AnimateSharedLayout>
  );
};
export default FeedbackList;

/* @jsx jsx */
import React, { useState, Fragment } from "react";
import { Grid, jsx } from "theme-ui";
import { Flex } from "./Flex";
import { H1, H2, P } from "./Typography";
import GlobalPrismStyles from "./GlobalPrismStyles";
import { graphql, useStaticQuery } from "gatsby";
import { ReactComponent as Code } from "heroicons/outline/code.svg";
import { ReactComponent as WordPress } from "../../static/wordpress.svg";
import { ReactComponent as Vue } from "../../static/vue.svg";
import { ReactComponent as ReactIcon } from "../../static/react.svg";
import { ReactComponent as Bootstrap } from "../../static/bootstrap.svg";
import { ReactComponent as ChevronLeft } from "heroicons/outline/chevron-left.svg";
import NotionRenderer from "./NotionRenderer";
import InputField from "./InputField";
import { FeedbackForm } from "../../../npm/src";
import {
  useGetCurrentUserQuery,
  useSendWidgetInstructionsMutation,
} from "../graphql/queries/_generated";
import { ReactComponent as CloseIcon } from "heroicons/solid/x.svg";
import Modal from "./Modal";

const frameworks = {
  html: {
    icon: Code,
    title: `HTML`,
  },
  wordpress: {
    icon: WordPress,
    title: "WordPress",
  },
  react: {
    icon: ReactIcon,
    title: "React",
  },
  vue: {
    icon: Vue,
    title: "Vue",
  },
  bootstrap: {
    icon: Bootstrap,
    title: `Bootstrap`,
  },
  headless: {
    icon: Code,
    title: "Build your own form",
  },
};

const ReachOutLink = (props: object) => (
  <a
    href="https://feedback.fish/feedback?pid="
    {...props}
    sx={{ color: `brandBlue.2`, textDecoration: `underline` }}
  >
    reach out anytime
  </a>
);

type Framework = keyof typeof frameworks;

type Props = {
  projectId: string;
};

const Onboarding: React.FC<Props> = ({ projectId }) => {
  const [selectedFramework, setSelectedFramework] = useState<Framework | null>(
    null
  );

  const [{ data: currentUserData }] = useGetCurrentUserQuery();
  const [inputValue, setInputValue] = React.useState("");
  const [
    { data: widgetInstructionData, fetching: sendingWidgetInstructions },
    sendWidgetInstructions,
  ] = useSendWidgetInstructionsMutation();
  const sentWidgetInstructions = !!widgetInstructionData;

  const data: Record<
    Framework,
    { title: string; description?: string; blockMap: string }
  > = useStaticQuery(graphql`
    {
      wordpress: reactNotionPage(slug: { eq: "/help/wordpress" }) {
        title
        description
        blockMap
      }
      react: reactNotionPage(slug: { eq: "/help/react" }) {
        title
        description
        blockMap
      }
      vue: reactNotionPage(slug: { eq: "/help/vue" }) {
        title
        description
        blockMap
      }
      bootstrap: reactNotionPage(slug: { eq: "/help/bootstrap" }) {
        title
        description
        blockMap
      }
      html: reactNotionPage(slug: { eq: "/help/html" }) {
        title
        description
        blockMap
      }
      headless: reactNotionPage(slug: { eq: "/help/headless" }) {
        title
        description
        blockMap
      }
    }
  `);

  return (
    <Fragment>
      <GlobalPrismStyles />
      {selectedFramework && (
        <button
          sx={{
            position: `absolute`,
            left: 0,
            // Magic number to align with modal top bar
            top: 0,
            p: 3,
            color: `gray.6`,
            zIndex: 3,
            "&:hover, &:focus": {
              outline: `none`,
              color: `blue.6`,
            },
          }}
          onClick={() => setSelectedFramework(null)}
        >
          <ChevronLeft sx={{ height: `1.5em` }} />
        </button>
      )}
      <div
        sx={{
          position: `relative`,
          overflow: selectedFramework ? `hidden` : `auto`,
          p: [4, 4, 5],
          pt: [4, 4, 4],
          height: `calc(100% - 57px)`,
        }}
      >
        <div sx={{ textAlign: "center", mb: 4 }}>
          <H1 sx={{ fontSize: [4, 4] }}>Get started with Feedback Fish</H1>
          <P sx={{ color: "gray.6" }}>
            Integrate the widget into your website to start collecting customer
            feedback. If you have any troubles{" "}
            <FeedbackForm
              projectId="f7c9b9b0b1002e"
              triggerComponent={ReachOutLink}
              userId={currentUserData?.currentUser?.email}
            />
            , we're here to help!
          </P>
        </div>

        <Grid gap={3} columns={[1, 1, 2]}>
          {(Object.keys(frameworks) as Array<Framework>).map((key) => {
            const Icon = frameworks[key].icon;

            return (
              <Flex
                key={key}
                as="button"
                data-splitbee-event="Select Framework"
                data-splitbee-event-location="Onboarding"
                data-splitbee-event-type={key}
                // @ts-ignore
                onClick={() => {
                  setSelectedFramework(key);
                }}
                gap={2}
                sx={{
                  alignItems: `center`,
                  bg: "#FFF",
                  borderRadius: "6px",
                  border: "1px solid",
                  textAlign: "left",
                  userSelect: "text",
                  overflow: "hidden",
                  backfaceVisibility: "hidden",
                  borderColor: "gray.3",
                  py: 2,
                  px: 3,
                  ":hover": {
                    boxShadow: "0 4px 5px rgba(0,0,0,0.04)",
                    bg: "gray.1",
                  },
                }}
              >
                <Icon sx={{ height: `1.5em`, width: `auto` }} />
                <span>{frameworks[key].title}</span>
              </Flex>
            );
          })}
        </Grid>

        <Flex
          alignItems="center"
          justifyContent="center"
          sx={{ position: `relative`, my: 4 }}
        >
          <hr sx={{ position: `absolute`, left: 0, right: 0, top: `50%` }} />
          <p
            sx={{
              bg: `white`,
              zIndex: 1,
              px: 3,
              color: `gray.6`,
              fontSize: 1,
            }}
          >
            or
          </p>
        </Flex>

        <P sx={{ color: "gray.6" }}>
          You can also send these instructions to your developer instead.
        </P>
        <Flex
          as="form"
          // @ts-ignore
          onSubmit={(evt) => {
            evt.preventDefault();
            sendWidgetInstructions({
              projectId,
              email: inputValue,
            }).finally(() => {
              setInputValue("");
            });
          }}
          alignItems="flex-end"
          flex="1"
          gap={2}
        >
          <InputField
            inputValue={inputValue}
            onChange={(text) => {
              setInputValue(text);
            }}
            label="Developer Email"
            placeholder="annette@mycompany.com"
            sx={{ flex: 1 }}
          />
          <button
            type="submit"
            disabled={sentWidgetInstructions || sendingWidgetInstructions}
            sx={{
              px: 3,
              display: "flex",
              alignItems: "center",
              borderRadius: 6,
              justifyContent: "center",
              backgroundColor: "white",
              border: "2px solid",
              color: sentWidgetInstructions ? "green.5" : "brandBlue.2",
              borderColor: sentWidgetInstructions ? "green.5" : "brandBlue.2",
              fontSize: `16px!important`,
              py: 2,
              height: `44px`,
              transition: "ease-in-out 100ms",
              transitionProperty: "background, box-shadow",
              boxShadow: "0 0 0 rgba(0, 93, 255, 0)",
              textDecoration: `none`,
              ":focus": {
                outline: "0",
                boxShadow: "0 0 0 3px rgba(0, 93, 255, 0.45)",
              },
              ":hover": {
                backgroundColor: sentWidgetInstructions ? "white" : "blue.1",
              },
            }}
          >
            {sentWidgetInstructions
              ? "Sent"
              : sendingWidgetInstructions
              ? "Sending..."
              : "Send"}
          </button>
        </Flex>
      </div>

      {selectedFramework && (
        <div
          sx={{
            position: `absolute`,
            top: `57px`,
            left: 0,
            right: 0,
            bottom: 0,
            p: [4, 4, 5],
            pt: [4, 4, 4],
            height: [`calc(100vh - 57px)`, `calc(100vh - 57px)`, `100%`],
            width: `100%`,
            overflow: `auto`,
            background: `white`,
            zIndex: 1,
          }}
        >
          <H2 sx={{ fontSize: [3, 4], mb: 3, mt: 0 }}>
            {data[selectedFramework].title}
          </H2>
          <NotionRenderer
            blockMap={JSON.parse(data[selectedFramework].blockMap)}
          />
        </div>
      )}
    </Fragment>
  );
};

export default (props: Props & { isOpen: boolean; onDismiss: () => void }) => (
  <Modal {...props}>
    <Flex
      sx={{
        p: 3,
        backgroundColor: `white`,
        borderBottom: `1px inset grey`,
        borderBottomColor: `gray.3`,
        height: `57px`,
      }}
      alignItems="center"
      justifyContent="flex-end"
    >
      <button
        sx={{
          color: `gray.6`,
          zIndex: 2,
          "&:hover, &:focus": {
            outline: `none`,
            color: `blue.6`,
          },
        }}
        onClick={props.onDismiss}
      >
        <span aria-hidden>
          <CloseIcon sx={{ width: `1.5em` }} />
        </span>
      </button>
    </Flex>
    <Onboarding projectId={props.projectId} />
  </Modal>
);

import { theme } from "twin.macro";
import {
  Categories,
  Feedback,
  Status,
  GetFeedbackQuery,
} from "./src/graphql/queries/_generated";
import { FilterCategory, Colors, SidebarOption } from "./types/app-types";

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function randomWord() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  const length = getRandomInt(3, 10);
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomSentence(length: number) {
  let result = "";
  for (let i = 0; i < length; i++) {
    result += randomWord() + " ";
  }
  return result;
}

export const colorMap: { [color in FilterCategory]: Colors } = {
  all: {
    primary: theme("colors.blue.500").toString(),
    secondary: theme("colors.blue.100").toString(),
    tertiary: theme("colors.blue.400").toString(),
  },
  [Categories.Issue]: {
    primary: theme("colors.red.600").toString(),
    secondary: theme("colors.red.200").toString(),
    tertiary: theme("colors.red.400").toString(),
  },

  [Categories.Idea]: {
    primary: theme("colors.orange.600").toString(),
    secondary: theme("colors.orange.200").toString(),
    tertiary: theme("colors.orange.400").toString(),
  },
  [Categories.Other]: {
    primary: theme("colors.gray.700").toString(),
    secondary: theme("colors.gray.300").toString(),
    tertiary: theme("colors.gray.400").toString(),
  },
  archive: {
    primary: theme("colors.gray.700").toString(),
    secondary: theme("colors.gray.300").toString(),
    tertiary: theme("colors.gray.400").toString(),
  },
};
export const randomSentences = new Array(50)
  .fill(0)
  .map((_, i) => randomSentence(i));

export const isHiddenFeedback = (feedback: Partial<Feedback>) =>
  feedback?.text?.split(" ").every((t) => t === "hidden");

export const createOption = (
  category: Categories,
  feedback: GetFeedbackQuery["feedback"]
): SidebarOption => ({
  category,
  style: "solid",
  count: feedback.filter(
    (f) => f.category === category && f.status !== Status.Archived
  ).length,
});

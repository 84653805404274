/* @jsx jsx */
import { jsx } from "theme-ui";

import { PageProps } from "gatsby";
import qs from "querystring";
import { Global, css } from "@emotion/core";

import { Layout } from "../../../components/Layout";
import SEO from "../../../components/Seo";
import Project from "../../../components/Project";

export default ({
  location,
  params,
}: PageProps & { params: { projectId: string } }) => {
  const search = qs.parse(location.search.substring(1));

  return (
    <Layout minimalFooter>
      <SEO
        title="Dashboard"
        description="Manage your users' feedback submissions."
      />
      <Global
        styles={css`
          .medium-zoom-image {
            z-index: 101 !important;
          }
          .medium-zoom-image--opened {
            will-change: unset !important;
          }
        `}
      />
      <Project id={params.projectId} success={search.success === "true"} />
    </Layout>
  );
};

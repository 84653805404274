/* @jsx jsx */
import React, { useEffect } from "react";
import { jsx } from "theme-ui";
import {
  Feedback,
  useUpdateFeedbackMutation,
  Status,
} from "../graphql/queries/_generated";
import { motion, AnimatePresence } from "framer-motion";
import CategoryBadge from "./CategoryBadge";
import { formatDistanceToNow } from "date-fns";
import { isHiddenFeedback, randomSentences } from "../../utils";
import { ReactComponent as Chevron } from "../../static/chevron.svg";
import tw from "twin.macro";
import mediumZoom from "medium-zoom";
import theme from "../gatsby-plugin-theme-ui";

import FeedbackDetail from "./FeedbackDetail";
import { notify } from "react-notify-toast";

interface FeedbackCardProps {
  feedback: Feedback;
  active: boolean;
  onSelect: () => void;
}

const zoomSettings = { background: "rgba(0,0,0,0.5)", margin: 48 };

const FeedbackCard: React.FC<FeedbackCardProps> = ({
  feedback: f,
  active,
  onSelect,
}) => {
  const zoom = React.useRef(mediumZoom(zoomSettings));

  useEffect(() => {
    if (!zoom.current) {
      zoom.current = mediumZoom(zoomSettings);
    }
  }, [active]);

  useEffect(() => {
    if (!active) {
      zoom.current.detach();
    }
  }, [active]);

  function attachZoom(image: any) {
    if (zoom.current.getImages().length === 0) {
      zoom.current?.attach(image);
    }
  }

  const [
    { fetching: isUpdatingFeedback },
    updateFeedback,
  ] = useUpdateFeedbackMutation();
  return (
    <motion.button
      key={f.id}
      layout={"position"}
      onClick={onSelect}
      tabIndex={0}
      aria-label={`Open ${f.category}`}
      onKeyDown={(e) => e.key === "Enter" && onSelect()}
      transition={{ duration: 0.3 }}
      initial={{
        scale: 1,
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        scale: active ? 1 : 0.972,
      }}
      sx={{
        bg: "#FFF",
        borderRadius: "10px",
        cursor: active ? "default" : "pointer",
        border: "1px solid",
        textAlign: "left",
        userSelect: "text",
        zIndex: active ? 1 : 0,
        width: "100%",
        overflow: "hidden",
        backfaceVisibility: "hidden",
        borderColor: active ? "gray.3" : "transparent",
        boxShadow: active ? "0 4px 5px rgba(0,0,0,0.04)" : "none",
        ":hover": {
          bg: active ? "#fff" : "gray.1",
        },
        ":focus": {
          bg: active ? "white" : "gray.1",
          borderColor: active ? "gray.4" : "gray.4",
          outline: "none",
        },
      }}
    >
      <div sx={{ p: 3 }}>
        <div sx={{ display: "flex", justifyContent: "space-between" }}>
          <CategoryBadge category={f.category} />
          <span sx={tw`text-sm text-gray-500 md:text-base`}>
            {formatDistanceToNow(new Date(f.createdAt), {
              addSuffix: true,
            })}
          </span>
        </div>

        <div sx={tw`flex items-end pt-3 pb-2`}>
          <p sx={tw`flex-1 whitespace-pre-wrap`}>
            {isHiddenFeedback(f) ? (
              <span
                sx={{
                  flex: 1,
                  textShadow: `0 0 16px rgba(0, 0, 0, 0.5)`,
                  color: `transparent`,
                  userSelect: `none`,
                  py: 3,
                }}
              >
                {randomSentences[(f.text.match(/hidden/g) || []).length] ||
                  randomSentences[randomSentences.length - 1]}
              </span>
            ) : (
              f.text
            )}
          </p>
          <AnimatePresence>
            {!active && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
                sx={tw`relative text-gray-500`}
              >
                <Chevron
                  sx={{
                    bottom: "-0.8rem",
                    right: "0.2rem",
                    transform: "rotate(90deg)",
                    position: "absolute",
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <AnimatePresence>
          {active && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: { duration: 0.3 },
              }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              sx={tw`grid grid-cols-1 gap-3 md:grid-cols-2`}
            >
              {f.userId && <FeedbackDetail title="User" value={f.userId} />}
              {f.device.os && f.device.client && (
                <FeedbackDetail
                  title="Device"
                  value={
                    <span>
                      {f.device.client.name} {f.device.client.version},{" "}
                      {f.device.os.name.replace("Mac", "macOS")}{" "}
                      {f.device.os.version}
                    </span>
                  }
                />
              )}
              {f.location && (
                <FeedbackDetail
                  title="Page"
                  value={
                    <a
                      tabIndex={-1}
                      href={f.location}
                      target="_blank"
                      sx={tw`text-gray-700 no-underline hover:text-gray-600 md:truncate`}
                    >
                      {f.location.replace(/^(https?):\/\//, "")}
                    </a>
                  }
                />
              )}
              {f.screenshotUrl && (
                <FeedbackDetail
                  title="Screenshot"
                  value={
                    <button
                      style={{
                        height: 22,
                        cursor: "zoom-in",
                        position: "relative",
                      }}
                      sx={tw`relative w-8 h-8 overflow-hidden border rounded`}
                      onClick={() => {
                        zoom.current?.open();
                      }}
                    >
                      <img
                        ref={attachZoom}
                        src={f.screenshotUrl}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{ zIndex: 2 }}
                        sx={{
                          pointerEvents: "none",
                          background:
                            "linear-gradient(0deg,rgba(0,0,0,0.15) 0%,rgba(255,255,255,0) 100%)",
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                          right: 0,
                          top: 0,
                        }}
                      />
                    </button>
                  }
                />
              )}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {active && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: { duration: 0.3 },
              }}
              exit={{ opacity: 0, transition: { duration: 0 } }}
              sx={tw`flex justify-end mt-3`}
            >
              <button
                data-splitbee-event="Archive"
                sx={tw`inline-block px-2 py-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded hover:text-gray-600`}
                disabled={isUpdatingFeedback}
                onClick={() => {
                  // Example feedback
                  if (f.id === -1) {
                    notify.show(
                      "This example will disappear once you receive your first feedback!",
                      "custom",
                      undefined,
                      {
                        background: theme.colors.brandBlue[0],
                        text: theme.colors.brandBlue[3],
                      }
                    );
                    return;
                  }
                  updateFeedback({
                    id: f.id,
                    status:
                      f.status === Status.Archived
                        ? Status.New
                        : Status.Archived,
                  });
                }}
              >
                {f.status === Status.Archived && `Unarchive`}
                {f.status === Status.New && `Archive`}
              </button>
              {f.userId && f.userId.includes("@") && (
                <a
                  data-splitbee-event="Reply with Mail"
                  href={`mailto:${f.userId}?subject=${encodeURIComponent(
                    `Re: Your feedback`
                  )}&body=${encodeURIComponent(`> ${f.text}`)}%0D%0A%0D%0A`}
                  sx={tw`inline-block px-2 py-1 ml-1 text-sm font-semibold text-blue-600 bg-blue-100 rounded hover:text-blue-400`}
                >
                  Reply with Mail
                </a>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {active && f.metadata && (
        <div sx={tw`relative px-3 pt-3 pb-1 mt-3 bg-gray-300`}>
          <div
            sx={tw`absolute top-0 left-0 flex justify-center w-full`}
            style={{ transform: "translateY(-10px)" }}
          >
            <div
              sx={tw`px-2 text-xs font-semibold tracking-wider text-white uppercase bg-gray-600 rounded-full`}
            >
              Metadata
            </div>
          </div>
          {f.metadata.map(({ key, value }) => (
            <div
              key={key}
              sx={tw`inline-flex max-w-full mb-2 mr-3 overflow-hidden text-sm rounded shadow-sm`}
            >
              <div sx={tw`px-2 text-white whitespace-no-wrap bg-gray-700`}>
                {key}
              </div>
              <div sx={tw`px-2 text-green-700 truncate bg-green-100`}>
                {value}
              </div>
            </div>
          ))}
        </div>
      )}
    </motion.button>
  );
};
export default FeedbackCard;

import { useEffect, useState } from "react";

// We need this for the infinite scroll to work with non-async data
const useFakeLoad = (duration: number) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading) {
      const t = setTimeout(() => setLoading(false), duration);
      return () => clearTimeout(t);
    }
  }, [loading, duration]);

  return [loading, setLoading] as const;
};
export default useFakeLoad;

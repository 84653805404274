/* @jsx jsx */
import { jsx } from "theme-ui";
import tw, { theme } from "twin.macro";
import { InputHTMLAttributes, useState } from "react";
import { Flex } from "./Flex";

const generateId = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

const InputField: React.FC<{
  inputValue: string;
  disabled?: boolean;
  placeholder: string;
  inputType?: InputHTMLAttributes<HTMLInputElement>;
  condensed?: boolean;
  onChange: (value: string) => void;
  label?: string;
  maxLength?: number;
}> = ({
  inputValue,
  disabled,
  onChange,
  placeholder,
  inputType,
  condensed,
  label,
  maxLength,
}) => {
  const [id] = useState(generateId);

  return (
    <Flex flexDirection="column" flexGrow={"inherit"} alignItems="flex-start">
      {label && (
        <label
          htmlFor={id}
          sx={{
            ...tw` text-gray-600 mb-1 tracking-normal text-sm font-medium`,
          }}
        >
          {label}
        </label>
      )}
      <input
        id={id}
        maxLength={maxLength}
        aria-label={placeholder + inputValue}
        type={inputType?.type}
        value={inputValue}
        disabled={disabled}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        placeholder={placeholder}
        sx={{
          ...tw`border border-gray-300 placeholder-gray-400 py-1 px-3 text-base text-gray-700 w-full focus:border-gray-500 focus:outline-none `,
          width: `100%`,
          borderRadius: 6,
          ":disabled": {
            ...tw`bg-gray-200 text-gray-600`,
          },
          paddingY: condensed ? "0.25rem" : "0.5rem",
        }}
      ></input>
    </Flex>
  );
};
export default InputField;

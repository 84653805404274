/* @jsx jsx */
import { jsx } from "theme-ui";
import { loadStripe } from "@stripe/stripe-js";
import { useCreateCheckoutMutation } from "../graphql/queries/_generated";

// NOTE: This needs to be called outside the component
const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_51H3dw2LfU4QizRMM2UmnaKqDIxgogKfnmZOx3krni8uPjlJ2sWEIQM3OgoA30NjHT5pSf01iR1YmT6XSbe4LwwTq00AnnLi3x2"
    : "pk_test_51H3dw2LfU4QizRMMHj3sN7PstgVwS3qn2ssHvv5zVo2yspikbIO2xMYzOqJvPCM7KQfffBVABnBuz224z4zs5PWv00LkD1cfTj"
);

export const useCheckoutHandler = (projectId: string) => {
  const [{ fetching }, createCheckout] = useCreateCheckoutMutation();
  const handleClick = async (evt: any) => {
    evt.preventDefault();

    const { data } = await createCheckout({
      projectId,
      /* TODO: Let people manually select plan: "enterprise" */
    });

    const id = data?.createCheckout?.id;

    if (!id) return;
    const stripe = await stripePromise;
    const { error } = await stripe!.redirectToCheckout({
      sessionId: id,
    });
  };
  return { handleClick, fetching };
};

export default function Checkout({ projectId }: { projectId: string }) {
  const { handleClick, fetching } = useCheckoutHandler(projectId);
  return (
    <button
      role="link"
      disabled={fetching}
      onClick={handleClick}
      data-splitbee-event="Click Upgrade"
      data-splitbee-event-location="upsell"
      sx={{
        backgroundColor: "brandOrange.3",
        border: "none",
        borderRadius: "full",
        //width: "100%",
        px: 3,
        mt: 3,
        fontWeight: "bold",
        color: "white",
        fontFamily: "Roboto",
        fontSize: 2,
        py: 2,
        cursor: "pointer",
        ":hover": {
          backgroundColor: "brandOrange.4",
        },
      }}
    >
      {fetching ? "Loading ..." : "Unlock your feedback for $19/m"}
    </button>
  );
}

/* @jsx jsx */
import { jsx } from "theme-ui";
import tw from "twin.macro";

const FeedbackDetail: React.FC<{
  title: string;
  value: string | JSX.Element;
}> = ({ title, value }) => (
  <div sx={tw`overflow-hidden`}>
    <div sx={tw`uppercase text-xs tracking-wider font-semibold text-gray-500`}>
      {title}
    </div>
    <div sx={tw`text-sm text-gray-700 font-semibold truncate`}>{value}</div>
  </div>
);
export default FeedbackDetail;

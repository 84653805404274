/* @jsx jsx */
import { jsx } from "theme-ui";
import { colorMap } from "../../utils";
import { Categories } from "../graphql/queries/_generated";

const CategoryBadge: React.FC<{ category: Categories }> = ({ category }) => (
  <div
    sx={{
      bg: colorMap[category].secondary,
      color: colorMap[category].primary,
      py: "2px",
      px: 3,
      fontWeight: "500",
      fontFamily: "Roboto",
      borderRadius: "20px",
      textTransform: "capitalize",
      fontSize: 1,
    }}
  >
    {category}
  </div>
);

export default CategoryBadge;

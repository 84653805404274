/* @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import tw from "twin.macro";
import { Link } from "gatsby";

const Sidebar: React.FC<{}> = ({ children }) => (
  <div sx={{ ...tw`mb-3 sm:sticky`, top: 5 }}>
    <div sx={tw`grid grid-cols-1 gap-4`}>{children}</div>
  </div>
);

export const SidebarTitle: FC<{}> = ({ children }) => (
  <div
    sx={tw`hidden m-3 text-sm font-bold tracking-wider text-left text-gray-500 uppercase md:block`}
  >
    {children}
  </div>
);

export const SidebarGroup: FC<{}> = (props) => (
  <div sx={tw`grid grid-cols-4 gap-2 md:grid-cols-1`} {...props} />
);

interface ISidebarButtonProps {
  text: string;
  onClick: () => void;
  active: boolean;
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  style?: "outline" | "solid" | "none";
  count?: number;
}

export const SidebarButton = ({
  text,
  onClick,
  active,
  colors,
  style,
  count,
}: ISidebarButtonProps) => (
  <button
    aria-label={`Show ${text}`}
    onClick={onClick}
    sx={{
      ...tw`flex justify-center flex-1 px-3 py-1 text-left border border-transparent rounded-md md:justify-between`,
      ...(active
        ? tw`font-semibold bg-blue-200 focus:bg-gray-200 focus:outline-none focus:border-gray-400`
        : tw`hover:bg-gray-300 focus:bg-gray-200 focus:outline-none focus:border-gray-400`),
      bg: active ? colors.secondary : "white",
      color: active ? colors.primary : "gray.6",
      userSelect: "text",
      ":focus": {
        outline: "none",
        borderColor: active ? colors.tertiary : "gray.4",
        bg: active ? colors.secondary : "gray.2",
      },
    }}
  >
    <div sx={tw`flex items-center`}>
      {style !== "none" && (
        <div
          sx={{
            ...tw`w-2 h-2 mr-1 rounded-full`,
            ...(style === "outline" && {
              boxShadow: `inset 0 0 0 2px ${
                active ? colors.primary : colors.tertiary
              }`,
            }),
            ...(style === "solid" && {
              bg: active ? colors.primary : colors.tertiary,
            }),
          }}
        />
      )}
      <span sx={tw`mx-1 capitalize`}>{text}</span>
    </div>
    {typeof count === "number" ? (
      <div sx={tw`hidden md:block`}>{count}</div>
    ) : null}
  </button>
);

export const SidebarLink: React.FC<{ href: string }> = ({ children, href }) => (
  <Link to={href} sx={tw`flex items-center flex-1 mx-3 text-sm text-blue-500`}>
    {children}
  </Link>
);

export default Sidebar;
